<template>
  <b-row>
    <b-col class="chat">
      <PageHeader :title="$t('general.massMessage.title')" />

      <b-row class="conversation" ref="conversation">
        <div class="wrapper">
          <div v-for="(message, index) in messages" :key="index">
            <ChatMessages :message="message" />
          </div>
        </div>
      </b-row>

      <b-row>
        <ui-media-uploader :key="media.length" v-model="media" ref="uploader" />

        <input
          ref="videoInput"
          type="file"
          :accept="imagesMediaType"
          @change="uploadFromDevice"
          hidden
        />

        <div class="user-chat-input">
          <b-col>
            <div class="user-chat-input__title mt-3">
              <span class="user-chat-input__font user-chat-input__font--title">
                {{ $t("general.massMessage.addMassMessage") }}
              </span>
            </div>
          </b-col>

          <div class="user-chat-input__input" v-if="true">
            <CTextarea
              v-model.trim="message"
              ref="text"
              theme="comments"
              @blur="updateCursorPosition"
              :placeholder="$t('general.chat.placeholder')"
            />
          </div>
        </div>

        <b-col>
          <div class="user-chat-input__title">
            <span
              class="user-chat-input__font user-chat-input__font--secondary"
            >
              {{ $t("general.massMessage.addVariable") }}
            </span>
          </div>

          <div class="user-chat-input__title mt-2 mb-2">
            <UiBadge
              v-for="placeholder in placeholderLIst"
              :key="placeholder"
              role="button"
              @click.native="pasteText(placeholder)"
              class="payout-card__badge"
              size="medium"
            >
              {{ placeholder }}
            </UiBadge>
          </div>
        </b-col>

        <div class="page-chat__footer">
          <button
            type="button"
            @click="mediaClick"
            class="button page-chat__button page-chat__button--setting"
          >
            <span class="page-chat__icon-wrapper">
              <CIcon name="image" path="messages" class="page-chat__icon" />
            </span>
          </button>

          <button
            v-if="isCreatorRole"
            type="button"
            v-b-modal="'modalPrice'"
            class="button page-chat__button page-chat__button--setting"
          >
            <span class="page-chat__icon-wrapper">
              <CIcon name="tip" path="messages" class="page-chat__icon" />
            </span>
          </button>

          <button
            type="button"
            @click.prevent="send"
            class="base-button page-chat__button page-chat__button--send"
            :class="{ 'page-chat__button--disabled': !message }"
          >
            <span class="base-button__font">{{ $t("general.send") }}</span>
          </button>
        </div>

        <b-modal
          id="modalPrice"
          centered
          hide-footer
          header-class="flow-modal__header"
          :title="$t('general.message-price')"
        >
          <b-input-group>
            <CInput
              type="number"
              name="price"
              input-mode="text"
              theme="comments"
              v-model="price"
              :placeholder="$t('general.free')"
            />
          </b-input-group>
        </b-modal>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import UiMediaUploader from "@/features/containers/post-create/components/UiMediaUploader.vue";
import PageHeader from "@/layout/PageHeader.vue";
import CIcon from "@/features/ui/CIcon.vue";
import ChatMessages from "@/features/containers/messages/ChatMessages.vue";
import CTextarea from "@/features/ui/CTextarea.vue";
import UiBadge from "@/features/ui/common/UiBadge.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import validationError from "@/mixins/validationError";
import waitRequest from "@/mixins/waitRequest";
import uploadFromDevice from "@/mixins/uploadFromDevice";
import CInput from "@/features/ui/СInput.vue";
import User from "@/components/models/User";
export default {
  name: "PageMassMessageChat",
  components: {
    CInput,
    UiBadge,
    CTextarea,
    ChatMessages,
    CIcon,
    PageHeader,
    UiMediaUploader,
  },
  mixins: [validationError, waitRequest, uploadFromDevice],
  props: {
    value: Array,
  },
  data() {
    return {
      form: {
        message: "",
        price: null,
        media: [],
      },
      messages: [],
      price: null,
      message: "",
      media: [],
      cursorPosition: 0,
    };
  },
  computed: {
    ...mapGetters({
      massMessage: "messages/massMessage",
    }),
    currentUser() {
      return this.$store.state.currentUser;
    },
    placeholderLIst() {
      return [" {name} ", " {username} "];
    },
    isCreatorRole() {
      return this.currentUser.role.includes(User.ROLE_CREATOR);
    },
  },

  methods: {
    ...mapActions({
      createMassMessage: "messages/createMassMessage",
    }),
    ...mapMutations({
      setMassMessageData: "messages/setMassMessageData",
    }),

    updateCursorPosition(textarea) {
      this.cursorPosition = textarea.selectionStart;
    },

    pasteText(text) {
      const beforeValue = this.message.substring(0, this.cursorPosition);
      const afterValue = this.message.substring(this.cursorPosition);

      this.message = beforeValue + text + afterValue;
    },

    mediaDropzoneClean() {
      this.$refs.uploader.cleanDropzone();
    },

    tip() {
      this.$popup.open("TipPopup");
    },

    send() {
      if (this.requestInProgress) return;

      this.setMassMessageData({
        ...this.massMessage,
        message: this.message,
        media: this.media || [],
        price: this.price,
      });
      this.$router.push({ path: "/messages", query: { send: 1 } });

      this.waitRequest(() => {
        this.createMassMessage({
          ...this.massMessage,
          message: this.message,
          media: this.media || [],
          price: this.price,
          validate_only: true,
        }).catch(this.checkErrors);
      });
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["modalPrice"].toggle("#toggle-btn");
    },
    showModal() {
      this.$refs["modalPrice"].show();
    },
    hideModal() {
      this.$refs["modalPrice"].hide();
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/vendors/_variables.scss";

textarea.form-control {
  border: none;
  scroll-behavior: smooth;
}
textarea.form-control:focus {
  border: none;
  box-shadow: none;
}
.chat {
  min-height: calc(var(--vh, 1vh) * 100);
  max-height: calc(var(--vh, 1vh) * 100);
  flex-direction: column;
  display: flex;
  .conversation {
    @include hideScroll();
    overflow-y: auto;
    overscroll-behavior: contain;
    flex: 1;
    display: flex;
    .wrapper {
      width: 100% !important;
      margin-top: auto;
      flex-direction: column-reverse;
      display: flex;
    }
  }
  .timeline {
    position: relative;
    display: flex;
    justify-content: center;
    &::before {
      content: "";
      position: absolute;
      height: 1px;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: $app-gray-2;
    }

    &__text {
      position: relative;
      z-index: 5;
      padding: 0 $spacer;
      background: $white;
    }

    &__font {
      color: $app-font-color-gray-2;
      font-family: $font-default;
      font-size: em(12);
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
@include media-breakpoint-down(xs) {
  .chat {
    min-height: calc(var(--vh, 1vh) * 100 - (#{$spacer} * 4));
    max-height: calc(var(--vh, 1vh) * 100 - (#{$spacer} * 4));
  }
}

.page-chat {
  &__footer {
    display: flex;
    align-items: center;
    width: 100%;
    margin: em(0) em(16) em(12);
  }

  &__icon-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: em(20);
    height: em(20);

    &--header {
      width: em(24);
      height: em(24);
      margin-left: em(16);
    }
  }

  &__icon {
    color: $black;
    width: em(17);
    height: em(17);
  }

  &__tooltip {
    padding: em(18) em(18) em(20);
  }

  &__button {
    &--setting {
      width: auto;

      &:not(:first-child) {
        margin-left: em(13);
      }
    }

    &--send {
      margin-left: auto;
    }

    &--tooltip {
      color: $black;
      text-decoration: none;
      justify-content: flex-start;

      &:not(:first-child) {
        margin-top: em(15);
      }

      &:active {
        transform: none;
        filter: none;
      }
    }

    &--disabled {
      pointer-events: none;
      filter: grayscale(80%);
    }
  }
}

.messages-header-button {
  &__button-wrapper {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: flex-start;
  }

  &__button {
    width: em(30);
    text-align: center;
  }

  &__icon {
    color: $app-gray-17;
    width: em(19);
    height: em(19);

    &--plus {
      width: em(14);
      height: em(14);
    }
  }
}

.user-chat-input {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $app-gray-2;
  width: 100%;
  background-color: white;

  &__avatar-wrapper {
    margin-top: em(24);
    margin-right: em(13);
    margin-left: em(14);
  }

  &__avatar {
  }

  &__input {
    margin: em(17) em(14) em(14);
    flex: 1;
  }

  &__icon {
    color: $app-gray-6;
    width: em(19);
    height: em(19);
  }

  &__font {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 12px;
    color: $black;

    &--title {
      line-height: 100%;
    }

    &--secondary {
      font-weight: 500;
      font-size: 10px;
      line-height: 120%;
      color: $app-gray-38;
    }
  }
}

.base-button {
  $parent: &;
  width: auto;

  display: inline-flex;
  align-items: center;

  border-radius: em(100, 24);
  border: 1px solid $app-blue;
  background-color: $app-blue;
  padding: em(7, 24) em(15, 24);
  color: $white;

  &__font {
    color: $white;
    text-align: center;
    font-family: $font-default;
    font-size: em(12, 16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }
}
</style>
